@import "@vmf/components/src/assets/style/_index.scss";








































































































































































































































.login-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #04255a center no-repeat;
    background-size: cover;
    min-height: 100%;
    min-width: 1200px;

    > .inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        > .header {
            letter-spacing: 5px;
            //font-size: 50px;
            //font-weight: bold;
            margin-bottom: 20px;
            font-size: 33px;
            color: #baf3f2;
            font-weight: 600;
        }
        > .body {
            box-sizing: border-box;
            background-color: rgba(0, 0, 0, 0.3);
            box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.3);
            padding: 32px;
            border-radius: 10px;
            width: 400px;
            color: #fff;

            > .form-input {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 40px;
                font-size: 15px;
                > .label {
                    flex: 0 0 auto;
                    text-align: right;
                    margin-right: 10px;
                }
                > .input {
                    position: relative;
                    flex: 1 1 1%;
                    > input,
                    > .group > input {
                        position: relative;
                        z-index: 100;
                        width: 100%;
                        height: 40px;
                        line-height: 40px;
                        font-size: 15px;
                        background-color: rgba(0, 0, 0, 0.4);
                        border: 1px solid rgba(0, 0, 0, 0.6);
                        color: #efef;
                        border-radius: 5px;
                        outline: none;
                        padding: 0 10px;
                        box-sizing: border-box;
                    }

                    > .group {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        > input {
                            flex: 1 1 1%;
                            width: 1%;
                        }

                        > .captcha-img {
                            flex: 0 0 auto;
                            display: block;
                            width: 100px;
                            height: 40px;
                            margin-left: 10px;
                        }
                    }
                    > .error {
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 100%;
                        color: #f00;
                    }
                }
            }
            > .submit {
                line-height: 40px;
                text-align: center;
                border-radius: 5px;
                cursor: pointer;
                user-select: none;

                padding: 0 15px;
                font-size: 16px;
                height: 40px;
                width: 100%;
                border: 0;
                background: #3fbbcc;
            }
        }
    }
}

@keyframes form-input-in {
    0% {
        opacity: 0;
        transform: translate(0, -100%);
    }
    100% {
        opacity: 1;
        transform: translate(0, 0);
    }
}

@keyframes form-input-out {
    0% {
        opacity: 1;
        transform: translate(0, 0);
    }
    100% {
        opacity: 0;
        transform: translate(0, -100%);
    }
}

.form-input-show-enter-active {
    animation: form-input-in 0.5s;
}

.form-input-show-leave-active {
    animation: form-input-out 0.5s;
}
